







































import { Component, Vue, Prop, Watch } from "vue-property-decorator";
import PDFJS from "@/pdfjs";
import Axios from "axios";
import { BASE_API_URL } from "@/config";
import PDFPage from "@/components/pdf/PDFPage.vue";

enum PDFViewerState {
  Loading,
  Loaded,
  Error
};

@Component({ components: { PDFPage } })
export default class PDFViewer extends Vue {

  @Prop({ required: true }) readonly userId: String;
  @Prop({ required: true }) readonly path: string;
  @Prop({ default: '/WesignForms/Mapped/' }) readonly awsPrefix: string;
  @Prop({ default: () => [] }) readonly initialFields!: any[];
  @Prop({ default: false }) fieldsDisabled: boolean;
  @Prop({ default: '' }) readonly formId: string;
  @Prop({ default: null }) readonly userSignature: string;
  @Prop({ default: '800px' }) readonly minWidth: string;

  isDisabled: boolean = false;

  public documentFields: any = [];
  public pages: any = [];

  public PDFViewerState = PDFViewerState;
  public state: PDFViewerState = PDFViewerState.Loading;
  
  $refs!: {
    wrapper: any,
    page: any,
  };

  public get isLoading() {
    return this.state === this.PDFViewerState.Loading
  }

  getUpdatedFields({ fields, pageNumber }) {
    this.documentFields = [
      ...this.documentFields.filter(field => field.page !== pageNumber),
      ...fields
    ];

    this.$emit('fieldsChanged', { documentFields: this.documentFields });
  }

  isFileLocked() {
    this.isDisabled = this.documentFields;

    this.isDisabled = this.documentFields
      .filter(field => field.needToBeSignedBy.length > 0)
      .every(field => !!field.data);
  }

  async mounted() {
    this.documentFields = this.initialFields || [];
    this.isFileLocked();
    this.processPDF();
  }

  async getPages(pdf, numberOfPages) {
    const promises = Array.from(Array(numberOfPages), (_, i) => i + 1)
      .map(async (number) => ({
        number,
        page: await pdf.getPage(number),
        fields: this.initialFields.filter(field => field.page === number)
      }));
    
    return Promise.all(promises);
  }

  async downloadPDF(file) {
    this.state = PDFViewerState.Loading;
    let response = await Axios.post(
      BASE_API_URL + "common/fetchDocument",
      { path: `${this.awsPrefix}${file}` }
    );
    this.state = PDFViewerState.Loaded;
    return response.data;
  }

  public async processPDF() {
    let pdf = null;

    try {
      const pdfDocument = await this.downloadPDF(this.path);
      pdf = await PDFJS.getDocument({ data: atob(pdfDocument) }).promise;
      this.state = PDFViewerState.Loaded;
    } catch (error) {
      this.state = PDFViewerState.Error;
    }

    if (this.state === PDFViewerState.Error) { return; }

    this.pages = await this.getPages(pdf, pdf.numPages);

    Vue.nextTick(() => { this.rescale(); });        
  }

  created() {
    setTimeout(() => {
      window.addEventListener("resize", this.rescale);
    }, 1000);
  }

  destroyed() {
    window.removeEventListener("resize", this.rescale);
  }

  private rescale() {
    if(!this.$refs.page) { return; }

    let height = 0;
    const wrapperWidth = this.$refs.wrapper.offsetWidth;

    this.$refs.page.forEach(page => {
      const canvasWidth = page.$refs.canvas.width;
      const canvasHeight = page.$refs.canvas.height;

      if (!wrapperWidth || !canvasWidth || !canvasHeight) { return; }

      const scale = this.$refs.wrapper.offsetWidth / canvasWidth;

      page.$refs.pdf.style.transform = `scale(${scale})`;
      page.$refs.pdf.style.height = `${canvasHeight * scale}px`;
      height += canvasHeight * scale;
    });

    this.$refs.wrapper.style.height = `${height + (this.pages.length * 30)}px`;
  }
}
