





























import { Component, Vue, Prop, Watch } from "vue-property-decorator";
import _ from "lodash";

@Component
export default class Signature extends Vue {
  @Prop() readonly userId!:string;
  @Prop() readonly needToBeSignedBy!:string[];
  @Prop() readonly fieldId!:string;
  @Prop() readonly data!: any;
  @Prop({ default: 1 }) readonly scale: number;

  signature:any = null;
  shouldBeSigned = false;
  zoom: any = 0.3;

  get pendingMySignature() {
    return this.shouldBeSigned && !this.signature;
  }

  /**
   * TODO @onelio
   * Create real hash, using the user, the image and the timestamp
   * Saving on the database
   */
  get hash() {
    const base64 = _.last(this.signature.split('base64,'));

    return typeof base64 === 'string'
      ? base64.slice(0, 16)
      : '';
  }

  @Watch('data', { deep: true })
  dataChanged(data) {
    this.signature = _.get(this.data, 'base64', this.signature);
  }

  signing() {
    if (!this.shouldBeSigned) { return; }

    this.$emit('onSign', {
      userId: this.userId,
      fieldId: this.fieldId
    });
  }

  async mounted() {
    this.shouldBeSigned = this.needToBeSignedBy.includes(this.userId);
    this.signature = _.get(this.data, 'base64', null);
  }
}
