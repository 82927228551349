












































import { Component, Vue, Prop, Watch } from "vue-property-decorator";
import moment from 'moment';
import _ from "lodash";

@Component({})
export default class DisclosuresList extends Vue {
  @Prop({ default: 'Disclosures' }) readonly title;
  @Prop({ required: true }) readonly initialDisclosuresState;
  @Prop({ default: false }) readonly collapse;
  @Prop({ default: false }) readonly showRevokeButton;
  @Prop() readonly borrowerInfo;

  isCollapsed: boolean = true;
  tooltipItem = null;
  tooltipVisible = false;

  originalDisclosuresGroups = [];
  disclosureGroups = [];

  toggleCollapse() {
    this.isCollapsed = !this.isCollapsed;
  }

  @Watch('disclosureGroups', { deep: true })
  onDisclosuresChange(groups) {
    const disclosures = groups.reduce((disclosures, group) => [ ...disclosures, ...group.disclosures ], []);
    this.$emit('onDisclosuresChange', disclosures);
  }

  isDisclosureGroupSelected(disclosureGroup) {
    return disclosureGroup.disclosures.every(disclosure => disclosure.documentStatus === 'Wesign Required');
  }

  toggleDisclosureGroup(disclosureGroup) {
    disclosureGroup.disclosures.forEach(disclosure => {
      disclosure.documentStatus =
        disclosure.documentStatus === 'Wesign Required'
          ? 'Wesign Not Required'
          : 'Wesign Required';
    });
  }

  deselectAll() {
    this.disclosureGroups.forEach(group => {
      group.disclosures.forEach(disclosure => {
        disclosure.documentStatus = 'Wesign Not Required';
      });
    });
  }

  getDisclosureGroupStatus(group) {
    // TODO 
    return _.get(_.head(group.disclosures), 'wesignStatus', null);
  }

  changeDisclosureGroupStatus(disclosureGroup, status) {
    disclosureGroup.disclosures.forEach(disclosure => {
      disclosure.documentStatus = status;
    });
  }

  revokeGroup(event, group) {
    event.stopPropagation();
    this.$emit('onRevokeGroup', group.disclosures);
  }

  get isAllSelected() {
    return this.disclosureGroups.every(group => this.isDisclosureGroupSelected(group));
  }

  get orderedDisclosuresGroups() {
    return _.orderBy(this.disclosureGroups, ['order', 'formId']);
  }

  getSignedDateText(date) {
    return moment(date).format("dddd, MMMM D, YYYY");
  } 

  toggleSelectAll() {
    this.isAllSelected
      ? this.disclosureGroups.forEach(group => {
        const initialDisclosure = this.originalDisclosuresGroups.find(g => g.formId === group.formId);
        this.changeDisclosureGroupStatus(group, this.isDisclosureGroupSelected(initialDisclosure) ? 'Wesign Required' : 'Wesign Not Required');
      })
      : this.disclosureGroups.forEach(group => this.changeDisclosureGroupStatus(group, 'Wesign Required'));
  }

  showTooltip(disclosure) {
    const item = this.initialDisclosuresState.find(initDisclosure => initDisclosure.name === disclosure.name);
    this.tooltipItem = this.getTooltipItems(item);

    this.tooltipVisible = true;
  }

  hideTooltip() {
    this.tooltipVisible = false;
  }

  getTooltipItems(item) {
    const wesignFields = item.fields.filter(field => field.toBeSignedBy && field.type === 'wesign');

    if (!wesignFields.length) {
      return;
    }

    const signaturesInfo = wesignFields.reduce((result, field) => {
      const wesignField = item.wesignData.find(wData => wData.fieldId === field.fieldId);

      if (field.toBeSignedBy === 'broker') {
        if (!result.find(i => i.signee === 'Broker')) {
          result = result.concat({
            signee: 'Broker',
            signedDate: wesignField.updatedOn
          });
        }
      } else {
        field.needToBeSignedBy.forEach(id => {
          const borrower = this.borrowerInfo.borrowerInfo.find(({ userId }) => userId === id);
          const { firstName, lastName } = borrower.personalInfo.name;
          const userType = `${field.toBeSignedBy.slice(0, 1).toUpperCase()}${field.toBeSignedBy.slice(1)}`;
          const userName = `${userType} ${firstName} ${lastName}`;

          if (!result.find(i => i.signee === userName)) {
            result = result.concat({
              signee: userName,
              signedDate: wesignField.updatedOn
            });
          }
        })
      }

      return result;
    }, []);

    return {
      name: item.name,
      items: signaturesInfo
    }
  }

  setDisclosureGroups() {
    this.disclosureGroups =
      _.cloneDeep(this.initialDisclosuresState).reduce((disclosureGroups, disclosure) => {
        const disclosureGroup = disclosure.formId ? disclosureGroups.find(d => d.formId === disclosure.formId) : null;

        disclosureGroup
          ? disclosureGroup.disclosures.push(disclosure)
          : disclosureGroups.push({
              formId: disclosure.formId,
              wesignIdentifier: disclosure.wesignIdentifier,
              locked: disclosure.locked,
              name: disclosure.name,
              order: disclosure.order,
              disclosures: [disclosure]
            });

        return disclosureGroups;
      }, []);
  }

  isDeclineDisclosure(disclosureGroup) {
    if(disclosureGroup.disclosures.filter(d => d.propagation === 'brokerRefusesDisclosures').length) return true;
  }

  mounted() {
    this.setDisclosureGroups();
    this.originalDisclosuresGroups = _.cloneDeep(this.disclosureGroups);
  }
}
