








































































import { Component, Vue, Prop, Watch } from "vue-property-decorator";
import SignaturePlace from '@/views/Signature.vue';
import _ from "lodash";

@Component({ components: { SignaturePlace } })

export default class PDFPage extends Vue {

  @Prop({ required: true }) readonly userId: string;
  @Prop({ required: true }) readonly page: any;
  @Prop() readonly initialFields: any;
  @Prop() readonly signature: string;
  @Prop() readonly pageNumber: number;
  @Prop({ default: false }) readonly fieldsDisabled: boolean;
  @Prop({ default: '' }) readonly formId: string;

  documentFields: any = []
  scale: number = 3;

  $refs!: {
    pdf: any,
    canvas: any
  };
  
  newSignature(data) {
    const field = this.documentFields.find(f => f.fieldId === data.fieldId);

    if (data.userId !== this.userId) { return; }
    if (!field.needToBeSignedBy.includes(this.userId)) { return; }
    if (!this.signature) { return; }

    field.data = {
      userId: data.userId,
      base64: this.signature
    };

    field.updatedOn = new Date();
    this.documentFields = Object.create(this.documentFields);
  }

  @Watch('documentFields', { deep: true })
  onFieldsChange(fields, oldFields) {
    this.$emit('fieldsChanged', { fields, pageNumber: this.pageNumber });
  }

  mounted() {
    this.documentFields = this.initialFields || [];

    this.documentFields.forEach(field => {
      field.originalData = _.clone(field.data);

      if (field.type !== 'wesign' && typeof field.data === 'object') {
        field.data = '';
      }

      if (!field.data) {
        field.data = field.defaultValue;
      }

      field.disabled = (!field.enabled || field.data !== field.defaultValue || field.source !== 'user' || this.fieldsDisabled);
    });

    this.drawPDF();
  }

  public async drawPDF() {

    const canvas = this.$refs.canvas;
    const context = canvas.getContext('2d');

    const viewport = this.page.getViewport({ scale: this.scale });

    canvas.height = viewport.height;
    canvas.width = viewport.width;
    this.$refs.pdf.style.width = `${viewport.width}px`;
    this.$refs.pdf.style.height = `${viewport.height}px`;

    await this.page.render({
      canvasContext: context,
      viewport
    }).promise;
  }
}
